import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Landing.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Landing",
    component: Home
  },
  {
    path: "/comprendre",
    name: "Comprendre",
    component: () => import("../views/Comprendre.vue")
  },
  {
    path: "/repondre",
    name: "Repondre",
    component: () => import("../views/repondre/Repondre.vue"),
  },
  {
    path: '/definissez-votre-strategie',
    name: "Definissez votre strategie",
    component: () => import("../views/repondre/DefineYourStrategy.vue"),
  },
  {
    path: '/creez-votre-plan-d-action',
    name: "Creez votre plan d'action",
    component: () => import("../views/repondre/CreateYourActionPlan.vue"),
  },
  {
    path: '/atteignez-vos-objectifs',
    name: "Atteignez votre objectif",
    component: () => import("../views/repondre/ReachYourObjectives.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue")
  },
  {
    path: '/bibliotheque',
    name: "Bibliotheque",
    component: () => import("../views/Library.vue"),
  },
  {
    path: '/mentions-legales',
    name: "MentionsLegales",
    component: () => import("../views/MentionsLegales.vue"),
  },
  {
    path: '/politique-de-confidentialite',
    name: "PolitiqueDeConfidentialite",
    component: () => import("../views/PolitiqueDeConfidentialite.vue"),
  },
  {
    path: '/politique-de-cookies',
    name: "Cookies",
    component: () => import("../views/Cookies.vue"),
  },
  {
    path: '/fr_decret_guide_decret_tertiaire',
    name: "DecouvrezNotreGuideDAnalyse",
    component: () => import("../views/landings/Landing1.vue"),
  },
  {
    path: '/fr_decret_support_webinaire_decret_tertiaire_4_etapes',
    name: "ConformiteDecretTertiaire",
    component: () => import("../views/landings/Landing2.vue"),
  },
  {
    path: '/fr_decret_kit_formation_decret_tertiaire',
    name: "KitDeFormation",
    component: () => import("../views/landings/Landing3.vue"),
  }
];

const router = new VueRouter({
  routes
});

export default router;
