<template>
  <footer>
    <div class="separator-green"></div>
    <div class="separator-yellow"></div>

    <section class="container container--flex">
      <header>
        <img src="/img/deepki_white.svg" alt="">
      </header>
      <div class="sitemap">
        <router-link to="/comprendre" class="sitemap-link col">Comprendre le décret tertiaire</router-link>
        <div style="font-weight: 600;font-size: 18px;" class="col">
          <router-link to="/repondre" class="sitemap-link">Répondre au décret tertiaire</router-link>
          <ul style="margin-top: 12px;">
            <li>
              <router-link to="definissez-votre-strategie" class="sitemap-link">
                Définissez votre stratégie
              </router-link>
            </li>
            <li>
              <router-link to="creez-votre-plan-d-action" class="sitemap-link">
                Créez votre plan d'action
              </router-link>
            </li>
            <li>
              <router-link to="atteignez-vos-objectifs" class="sitemap-link">
                Atteignez vos objectifs
              </router-link>
            </li>
          </ul>
        </div>
        <router-link to="/bibliotheque" class="sitemap-link col">Bibliothèque</router-link>
        <div class="sitemap-column col">
          <DtButton link="/contact" color="#1FDFD5" font="white">
            Nous contacter
          </DtButton>
          <strong>Deepki</strong>
          <p>7, villa du Clos Malevart</p>
          <p>75011 Paris - FRANCE</p>
          <a href="tel:+33146060919">+33 1 46 06 09 19</a>

          <ul class="menu-social white ul--reset">
            <li><a target="_blank" rel="noopener" href="https://www.linkedin.com/company/deepki/"><img src="/img/in.png" alt=""></a></li>
            <li><a target="_blank" rel="noopener" href="https://www.facebook.com/DeepkiSAS/"><img src="/img/fb.png" alt=""></a></li>
            <li><a target="_blank" rel="noopener" href="https://twitter.com/DeepkiSAS"><img src="/img/tw.png" alt=""></a></li>
          </ul>
        </div>
      </div>
    </section>
    <p class="container miniline">© Copyright {{ new Date().getFullYear()}} Deepki SAS • 
      <router-link tag="span" to="/mentions-legales">Mentions légales, &nbsp;</router-link>  
      <router-link tag="span" to="/politique-de-confidentialite">Politique de confidentialité &nbsp;</router-link> et
      <router-link tag="span" to="/politique-de-cookies">Politique de cookies </router-link>  
    </p>
  </footer>
</template>

<script>
import DtButton from "@/components/DtButton.vue";
export default {
  components: {
    DtButton
  }
  
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";

footer {
  background-color: $black;
}

.separator-green {
  height: 12px;
  width: 100%;
  background-color: $green;
}

.separator-yellow {
  height: 16px;
  width: 100%;
  background-color: $secondary;
}

section {
  display: flex;
  justify-content: center;
  align-items: center;
}

header {
  width: 100%;
  margin: 0 64px;
  border-bottom: 1px solid white;
  img {
    width: 300px
  }
}

.sitemap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 42px;
  color: white;

  @media(max-width: 1024px){
    flex-direction: row;
    justify-content: center;

    .col {
      display: flex;
      flex-direction: column;
      min-width: 50%;
    }
  }

  @media(max-width: 600px){
    flex-direction: row;
    justify-content: center;

    .col {
      display: flex;
      flex-direction: column;
      min-width: 100%;
      align-items: center;
      margin: 24px 0;
    }
  }

  &-link {
    text-decoration: none;
    color: white;
    font-weight: 600;
    font-size: 18px;

    &:hover {
      color: $secondary;
    }

  }
  &-column {
    display: flex;
    flex-direction: column;

    strong {
      padding-top: 24px!important;
      font-weight: 600;
    }

    > strong, a, p {
      padding: 6px 0; 
    }

    > a {
      font-weight: 600;
      color: white;
    }
  }

  li {
    .sitemap-link {
      display: block;
      padding: 12px;
      font-weight: 400;
      font-size: 16px;
    }
  }
}

.miniline {
  font-size: 12px;
  color: white;
  padding: 24px;
  font-weight: 400;

  & span {
    font-weight: 600;
    cursor: pointer;
  }
}

.menu-social {
  display: flex;
  flex-direction: row;
  img {
    width: 48px;
    margin-right: 4px;

    &:hover {
      background-color: rgba(0,0,0, .2);
    }
  }
}
</style>
