<template>
  <section class="hero">
    <header class="hero-header">
      <span/>
      <p>
        {{ title }}
      </p>
      <span/>
    </header>
    <div class="tech-slideshow">
      <ul class="hero-logos" v-if="logos">
        <li v-for="(logo, index) in listToDisplay" :key="index">
          <img :src="logo" alt="">
        </li> 
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Ils nous font confiance"
    },
    logos: {
      type: Array, 
      default: () => ([])
    }
  },
  computed: {
    uniqList() {
      return Array.from(new Set(this.logos))
    },
    translateX() {
      return (-this.uniqList.length  / this.listToDisplay.length) * 100
    },
    listToDisplay() {
      return this.logos.concat(this.logos)
    }
  },
  mounted() {
    document.querySelector('.hero-logos').animate([ 
      {
        transform: "translateX(0)",
        color: "#fff"
      }, 
      {
        transform: `translateX(${this.translateX}%)`,
        color: "#000"
      }
    ], {
      duration: 25000,
      iterations: Infinity
    })
  }
}
</script>

<style lang="scss">
@import "@/assets/style/variables.scss";
.hero {
  padding: 32px 0;

  @media (max-width: 768px) {
    padding: 32px 0;
  }

  header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    line-height: 1.2;
    padding: 0 32px;

    & span {
      display: flex;
      flex-grow: 1;
      height: 1px;
      background-color: $grey;
      opacity: .5;
      @media (max-width: 768px) {
        display: none;
      }
    }

    & p {
      padding:0 48px;
      @media (max-width: 768px) {
        padding:0 24px;
        text-align: center;;
      }
    }
  }

  &-logos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 0;

    & li {
      padding: 0 6px;
      height: 200px;
      width: 180px;
      min-width: 180px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      @media (max-width: 768px) {
        max-width: 50%;
      }
    }
  }
}

.tech-slideshow {
  height: 200px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  > .hero-logos {
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transform: translate3d(0, 0, 0);
  }
}
</style>

