<template>
  <main class="main">
    <DtSlider :slides="mainSliderContent"/>
    <Hero :logos="logoList"/>
    <DtSliderSmall style="background-color: #4A4F5F; color: white;" 
                  title="Des ressources utiles"
                  button-text="Découvrir toutes les ressources"
                  button-link="/bibliotheque"
                  :slides="librarySliderContent"/>
  </main>
</template>

<script>
import DtSlider from "@/components/DtSlider.vue";
import DtSliderSmall from "@/components/DtSliderSmall.vue";
import Hero from "@/components/Hero.vue";
import landing from '../../public/json/decret-tertiaire-landing.json'
import slides from '../../public/json/decret-tertiaire-slides.json'

export default {
  components: {
    DtSlider, Hero, DtSliderSmall
  },
  metaInfo: {
    meta: [{
      name: 'description',
      content: 'Découvrez la réponse à toutes vos questions concernant le décret tertiaire et comment vous faire accompagner pour vous mettre en conformité.'
    }]
  },
  computed: {
    mainSliderContent() {
      return landing.filter(f => f.type === 'slider')
    },
    librarySliderContent() {
      return slides
    },
    logoList() {
      return [
        "img/logos/logo_2.png",
        "img/logos/logo_3.png",
        "img/logos/logo_4.png",
        "img/logos/logo_5.png",
        "img/logos/logo_6.png",
        "img/logos/logo_7.png",
        "img/logos/logo_8.png",
        "img/logos/logo_10.png",
        "img/logos/logo_11.png",
        "img/logos/logo_12.png",
        "img/logos/logo_13.png",
        "img/logos/logo_14.png",
        "img/logos/logo_15.png"
      ]
    }
  }
};
</script>


