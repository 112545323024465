<template>
  <nav class="nav">
    <router-link to="/" class="centered">
      <img src="/img/deepki_black.svg" class="nav-logo"/>
    </router-link>
    <router-link to="/comprendre" class="nav-link">
      Comprendre le décret tertiaire
      <div class="nav-deco"></div>
    </router-link>
    <div class="nav-link nav-link--hover">
      <router-link to="/repondre" class="nav-link">Répondre au décret tertiaire</router-link>
      <div class="nav-deco"></div>
      <ul class="submenu">
        <li>
          <router-link to="/definissez-votre-strategie" class="nav-link">
            Définissez votre stratégie
          </router-link>
        </li>
        <li>
          <router-link to="creez-votre-plan-d-action" class="nav-link">
            Créez votre plan d'action
          </router-link>
        </li>
        <li>
          <router-link to="atteignez-vos-objectifs" class="nav-link">
            Atteignez vos objectifs
          </router-link>
        </li>
      </ul>
    </div>
    <router-link to="/bibliotheque" class="nav-link">
      Bibliothèque
      <div class="nav-deco"></div>
    </router-link>
    <DtButton class="nav-button centered" link="/contact">
      Nous contacter
    </DtButton>
  </nav>
</template>

<script>
import DtButton from "@/components/DtButton.vue";
export default {
  components: {
    DtButton
  },
  props: {
    
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";

.nav {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: $headerHeight;
  padding: 32px;
  background-color: white;

  @media (max-width: 768px) {
    position: inherit;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  @media (max-width: 1180px) {
    padding: 24px 6px;
  }


  &-link {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 100%;
    min-height: 56px;
    max-width: 150px;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.15;
    color: $black;
    transition: all .1s ease-in;
    cursor: pointer;

    &:hover {
      color: $secondary;
    } 

    @media (max-width: 1180px) {
      font-size: 18px;
      padding: 0 12px;
    }

    @media (max-width: 768px) {
      width: 100%;
      max-width: 100%;
      padding: 12px 0;
    }

    &--hover:hover, .submenu:hover {
      @media (min-width: 768px) {
        .submenu {
          display: flex;
        }
      }
    }
  }

  &-deco {
    position: absolute;
    left: 100%;
    height: 2px;
    margin: auto;
    width: 24px;
    background-color: $black;

    @media (max-width: 1180px) {
      display: none;
    }

    &::before {
      content: "";
      position: absolute;
      top: -16px;
      width: 2px;
      height: 32px;
      background-color: $black;
    }

    &::after {
      content: "";
      position: absolute;
      left: 100%;
      top: calc(50% - 9px);
      width: 14px;
      height: 14px;
      border-radius: 50%;
      border: 2px solid $black;
    }
  }

  &-button {
    @media (max-width: 768px) {
      margin-top: 12px;
    }
  }

  &-logo {
    width: 200px;

    @media (max-width: 1180px) {
      width: 180px;
    }
  }
}

.router-link-exact-active {
  color: $secondary;
}

.centered {
  @media (max-width: 768px) {
    margin: auto;
  }
}

.submenu {
  display: none;
  position: absolute;

  flex-direction: column;
  top: 100%;
  width: 220px;
  color: $black;
  background-color: white;

  li {
    padding: 4px 32px;
    border: 1px solid $secondary;
    &:hover {
      color: $secondary;
    }
  }
}
</style>