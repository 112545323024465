<template>
  <section class="slider">
    <div class="container container--flex">
      <header>
        <span/>
        <p>
          {{ title }}
        </p>
        <span/>
      </header>
      <carousel class="carousel" v-bind="carouselOptions">
        <slide class="slide" v-for="(slide, index) in slides" :key="index">
          <a class="slide-content" :href="slide.link" target="_blank">
            <img class="slide-image" :src="slide.image">
            <span class="tag">
              {{ slide.tag }}
            </span>
            <p>
              {{ slide.text }}
            </p>
          </a>
        </slide>
      </carousel>

      <DtButton v-if="buttonLink" color="#1FDFD5" :link="buttonLink" style="margin-top: 24px;">
        {{ buttonText }}
      </DtButton>
    </div>
  </section>
</template>

<script>
import DtButton from "@/components/DtButton.vue";
import { Carousel, Slide } from 'vue-carousel';

export default {
  components: {
    Carousel, Slide, DtButton
  },
  props: {
    title: {
      type: String, 
      default: "Des ressources utiles"
    },
    slides: {
      type: Array
    },
    buttonText: {
      type: String
    },
    buttonLink: {
      type: String
    }
  },
  computed: {
    carouselOptions() {
      return {
        autoplay: false,
        paginationEnabled: false,
        navigationEnabled: true,
        navigationNextLabel: '',
        navigationPrevLabel: '',
        perPageCustom:[[0, 1],[500, 2],[768, 3]]
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";

.carousel {
  max-width: 100%;
}

.slider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 32px;

  @media (max-width: 768px) {
    padding: 32px 0;
  }

  header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    line-height: 1.2;
    width: 100%;
    padding-bottom: 24px;

    & span {
      display: flex;
      flex-grow: 1;
      height: 1px;
      background-color: $grey;
      opacity: .5;
      @media (max-width: 768px) {
        display: none;
      }
    }

    & p {
      padding:0 48px;
      @media (max-width: 768px) {
        padding:0 24px;
        text-align: center;;
      }
    }
  }

}
.slide {
  padding: 0 16px;
  @media (min-width: 768px) {
    max-width: calc(100% / 3);
  }
  &-image {
    width: 280px;
    max-width: 100%;

  }

  &-content {
    text-decoration: none;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .1s ease-in;

    &:hover {
      transform: scale(1.01);
    }

    span {
      margin: 8px auto 16px 32px; 
    }
    p {
      line-height: 1.2;
      font-size: 18px;
      margin: 8px auto 16px 32px; 
    }
  }
}

</style>
