<template>
  <carousel class="carousel" v-bind="carouselOptions">
      <slide class="slide" v-for="(slide, index) in slides" :key="index" :style="`background-image: url(${slide.image});`">
        <div class="slide-content">
          <div class="slide-backdrop"/>
          <component class="slide-title" :is="index === 0 ? 'h1' : 'h2'" v-html="slide.title"/>
          <div class="slide-paragraph">
            <h3>{{ slide.paragraph_title }}</h3>
            <h4>{{ slide.paragraph_text }}</h4>
          </div>
          <DtButton class="slide-button" :link="slide.button_link" color="#1FDFD5">
            {{ slide.button_text }}
          </DtButton>
        </div>
      </slide>
    </carousel>
</template>

<script>
import DtButton from "@/components/DtButton.vue";
import { Carousel, Slide } from 'vue-carousel';

export default {
  components: {
    Carousel, Slide, DtButton,
  },
  props: {
    slides: {
      type: Array, 
      default: () => ([])
    }
  },
  computed: {
    carouselOptions() {
      return {
        autoplay: true,
        autoplayTimeout: 10000,
        autoplayHoverPause: true,
        perPage: 1,
        paginationEnabled: false,
        navigationEnabled: true,
        navigationNextLabel: '',
        navigationPrevLabel: ''
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";

.slide {
  position: relative;
  max-width: 100%;
  height: 600px;
  max-height: 100vh;

  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    background-position: 0%;
  }

  &-content {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 24px 20vw;
    height: 100%;
  }

  &-backdrop {
    z-index: 0;
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: $black;
    opacity: .66;
  } 

  &-title {
    position: relative;
    width: 100%;
    max-width: 800px;
    color: white;
    font-size: 64px;
    font-weight: 600;
    text-transform: uppercase;

    @media (max-width: 768px) {
      font-size: 32px;
    }

  }
  h2::before, h1::before {
    content: '';
    position: absolute;
    top:0;
    width: 10px;
    height: 100%;
    left: -64px;
    background-color: $secondary;
  }

  &-paragraph {
    position: relative;
    padding-top: 48px;
    padding-bottom: 24px;
    color: white;
    font-weight: 600;
    font-size: 30px;
    max-width: 550px;

    @media (max-width: 768px) {
      font-size: 15px;
    }

    h4 {
      font-size: 24px;
      font-weight: 400;

    @media (max-width: 768px) {
      font-size: 14px;
    }
    }
    h3, h4 {
      line-height: 1.2;
    }
  }

  &-button {
    position: relative;
    min-width: 200px;
  }

}
</style>

