<template>
  <router-link :style="style" :to="link">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  props: {
    color: {
      default: "#3B3D3E",
      type: String
    },
    font: {
      type: String,
      default: "white"
    },
    link: {
      type: String,
    }
  },
  computed: {
    style() {
      return {
        backgroundColor: this.color,
        color: this.font
      }
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  min-height: 36px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  min-width: 140px;
  padding: 0 24px;
  cursor: pointer;
  text-align: center;

  @media (max-width: 475px) {
    padding: 4px 24px;
  }
}
</style>