<template>
  <main class="app">
    <nav-bar/>
    <router-view />
    <DtFooter/>
  </main>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import DtFooter from "@/components/DtFooter.vue";

export default {
  components: {
    NavBar, DtFooter
  },
  updated() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss">
@import '@/assets/style/main.scss';
.app {
  font-family: 'Roboto', 'Lato', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
}

.container {
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  overflow: hidden;

  &--flex {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.VueCarousel-navigation-next {
  right: 40px!important;
  width: 24px;
  height: 24px;
  background-image: url('/img/right-chevron.svg');
  outline: 0!important;

  @media (max-width: 1180px) {
    right: 20px!important;
  }
}
.VueCarousel-navigation-prev {
  left: 40px!important;
  width: 24px;
  height: 24px;
  background-image: url('/img/right-chevron.svg');
  transform: translateY(-50%) translateX(-100%) rotate(180deg)!important;
  outline: 0!important;

  @media (max-width: 1180px) {
    left: 20px!important;
  }
}
.VueCarousel-navigation--disabled {
  opacity: .2!important; 
}

.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: $black;
  border-radius: 10px;
  padding: 6px 12px;
  font-size: 16px;
}

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: $headerHeight;
  min-height: 500px;

  @media (max-width: 768px) {
    margin-top: 0;
  }
}

strong {
  font-weight: 700!important;
}
</style>

